import { toggleHamburger } from "./toggleHamburger";
import { accordion } from "./accordion";
import { tabMenu } from "./tabMenu";
import { initializeViewport } from "./initializeViewport";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
accordion();
tabMenu();
initializeViewport();

const topMvSliderElement = document.querySelector(".js-top-mv");
if (topMvSliderElement) {
  const topMvSlider = new Splide(topMvSliderElement, {
    breakpoints: {
      768: {},
    },
    type: "fade",
    pagination: false,
    arrows: false,
    autoplay: true,
    rewind: true,
    focus: "center",
    autoWidth: true,
    flickMaxPages: 1,
    flickPower: 50,
    pauseOnHover: false,
    pauseOnFocus: false,
  }).mount();
  topMvSlider.on("resize", () => {
    topMvSlider.refresh();
  });
}

const activitySLiderElement = document.querySelector(".js-activity-slider");
if (activitySLiderElement) {
  const activitySlider = new Splide(activitySLiderElement, {
    breakpoints: {
      768: {
        gap: "20px",
      },
    },
    type: "loop",
    pagination: false,
    arrows: false,
    autoplay: true,
    // perPage: 4,
    rewind: true,
    autoWidth: true,
    gap: "85px",
    perMove: 1,
  }).mount();
  activitySlider.on("resize", () => {
    activitySlider.refresh();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});
gsap.registerPlugin(ScrollTrigger);
gsap.utils.toArray(".js-gsap-fade-in-up").forEach((parentElement) => {
  gsap.fromTo(
    parentElement.querySelectorAll(".js-gsap-fade-in-up__child"), // 子要素を選択
    {
      opacity: 0,
      y: 30,
    },
    {
      opacity: 1,
      y: 0,
      duration: 1.0, // 各アニメーションの時間
      ease: "power3.out",
      stagger: 0.3, // 各要素のアニメーション間の遅延
      scrollTrigger: {
        trigger: parentElement, // トリガーとなる要素
        start: "top 70%", // アニメーションを開始する位置（画面の80%位置で開始）
        end: "bottom 20%", // アニメーションを終了する位置
        toggleActions: "play none none none", // スクロールアクションの設定（再生のみ）
      },
    }
  );
});
