function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
function saveInput() {
  console.log("Saving data");
}
const processChange = debounce(() => saveInput());

export const initializeViewport = () => {
  const handleResize = () => {
    const minWidth = 375;
    const value =
      window.outerWidth > minWidth
        ? "width=device-width,initial-scale=1"
        : `width=${minWidth}`;
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport && viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  };

  const debouncedResize = debounce(handleResize, 250);

  window.addEventListener("resize", debouncedResize, false);
  handleResize();
};

initializeViewport();
